import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../../public/verify/app/assets/lng/en.json';
import es from '../../public/verify/app/assets/lng/es.json';
import frCa from '../../public/verify/app/assets/lng/fr-ca.json';
import pt from '../../public/verify/app/assets/lng/pt.json';

export function initLng(lng = 'en') {
  i18n.use(initReactI18next).init({
    resources: {
      en: { ...en },
      es: { ...es },
      pt: { ...pt },
      frCa: { ...frCa },
    },
    lng: lng,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong'],
    },
  });
}
